
export const homeConstants = {
  coverImagePath: 'coverImg.webp',
  coverImageAlt: 'Cover Image',
  coverTitle: 'Living Pictures',
  coverSubtitle: 'Photography in Southeast Asia',
  coverSubtitle2: 'Chermaine Toh',
  creditTextMd: 'Image: Le Minh Truong. *Soliders in U Minh*. 1973, printed 2006. Gelatin Silver print on paper, 29.3 × 39.2 cm. Collection of National Gallery Singapore.',
  homeTextMd: `
This richly illustrated catalogue examines the power of photography and its mobilisation within
systems of knowledge and representation across Southeast Asian societies.
Rather than just thinking about what photographs show, *Living Pictures* explores what photographs do,
acknowledging that photographs have lives—they move and they act—and in the process,
they affect the world around them.

This groundbreaking catalogue accompanies the world’s first-ever survey of the medium’s histories
across Southeast Asia, from its earliest beginnings in the 19<sup>th</sup> century until its diverse
contemporary manifestations. It traces the creation, circulation and consumption of photography and
how these processes have shaped the visual regimes of the region, through essays by the
*Living Pictures* curators, interviews with artists and photographers featured in the exhibition,
comprehensive plates including never-before published images, and new research by leading international
scholars focusing on the interdisciplinary intersections between photography and art history,
archaeology and cultural theory.
  `,
};
